import eth from '../images/markets/eth.svg'
import ela from '../images/markets/ela.svg'
import usdt from '../images/markets/usdt.svg'
import hfil from '../images/markets/hfil.svg'
import ht from '../images/markets/ht.svg'
import bnb from '../images/markets/bnb.png'
import husd from '../images/markets/husd.svg'
import hbtc from '../images/markets/hbtc.svg'
import heth from '../images/markets/heth.svg'
import hdot from '../images/markets/hdot.svg'
import hbch from '../images/markets/hbch.svg'
import hltc from '../images/markets/hltc.svg'
import hbsv from '../images/markets/hbsv.svg'
import hxtz from '../images/markets/hxtz.svg'
import neo from '../images/markets/neo.svg'
import aave from '../images/markets/aave.svg'
import uni from '../images/markets/uni.svg'
import sushi from '../images/markets/sushi.svg'
import snx from '../images/markets/snx.svg'
import mdx from '../images/markets/mdx.png'
import link from '../images/markets/link.svg'
import depthFilda from '../images/markets/depth_filda.svg'
import usdc from '../images/markets/usdc.svg'
import dai from '../images/markets/dai.svg'
import tusd from '../images/markets/tusd.png'
import busd from '../images/markets/busd.png'
import matic from '../images/markets/matic.svg'
import quick from '../images/markets/quick.png'
import hmatic from '../images/markets/hmatic.png'
import WHT_USDT from '../images/markets/WHT-USDT.svg'
import HBTC_USDT from '../images/markets/HBTC_USDT.svg'
import ETH_USDT from '../images/markets/ETH_USDT.svg'
import ETH_HBTC from '../images/markets/ETH_HBTC.svg'
import iotx from '../images/markets/iotx.svg'
import glide from '../images/markets/glide.svg'

import kcs from '../images/freshBank/markets/kcs.png'
import skcs from '../images/freshBank/markets/skcs.jpg'

import unstoppableDomains from '../images/v2/ud.png'
import wallerMetamask from '../images/v2/header-wallet-metamask.svg'
import wallerWalletconnect from '../images/v2/header-wallet-walletconnect.svg'
import wallerKucoinWallet from '../images/v2/halo_logo.png';
import wallerTokenPocket from '../images/v2/header-wallet-tokenpocket.svg'
import wallerBitKeep from '../images/v2/header-wallet-bitkeep.png'
import networkHeco from '../images/v2/header-network-heco.svg'
import networkHecoModal from '../images/v2/header-network-hecoModal.svg'
import networkMatic from '../images/v2/header-network-matic.svg'
import networkMaticModal from '../images/v2/header-network-maticModal.png'
import networkBsc from '../images/v2/header-network-bsc.svg'
import networkBscModal from '../images/v2/header-network-bscModal.svg'
import networkIotex from '../images/v2/header-network-iotex.svg'
import networkArbitrum from '../images/v2/header-network-arbitrum.svg'
import networkEsc from '../images/v2/header-network-esc.svg'
import networkKcc from '../images/freshBank/header-network-kcc.svg'

//partners logos
import KCC from '../images/freshBank/partners/KCC.svg'
import MojitoSwap from '../images/freshBank/partners/MojitoSwap.svg'
import KucoinVentures from '../images/freshBank/partners/KucoinVentures.svg'
import KucoinWallet from '../images/freshBank/partners/KucoinWallet.svg'
import BitKeep from '../images/freshBank/partners/BitKeep.png'
import Coinhub from '../images/freshBank/partners/Coinhub.svg'
import CoinMarketCap from '../images/freshBank/partners/CoinMarketCap.png'
import FoxWallet from '../images/freshBank/partners/FoxWallet.png'
import BefiWallet from '../images/freshBank/partners/BefiWallet.png'
import KuPay from '../images/freshBank/partners/KuPay.png'
import Defillama from '../images/freshBank/partners/Defillama.png'
import TokenPocket from '../images/freshBank/partners/TokenPocket.png'
import Mathwallet from '../images/freshBank/partners/Mathwallet.png'

// community logos
import Twitter from '../images/freshBank/community/Twitter.svg'
import Discord from '../images/freshBank/community/Discord.svg'
import Telegram from '../images/freshBank/community/Telegram.svg'
import Medium from '../images/freshBank/community/Medium.svg'

import contractABI from './contractABI.json'
import QsMdxLPDelegate from './QsMdxLPDelegate.json'
import QsQuickLPDelegate from './QsQuickLPDelegate.json'
import QsConfigABI from './QsConfig.json'
import QsPriceOracleForEla from './QsPriceOracleForEla.json'
import MirroredVotingEscrowABI from '../lib/MirroredVotingEscrowABI'

const glideDecimals = 18
const glideAddress = '0xd39eC832FF1CaaFAb2729c76dDeac967ABcA8F27'

const rpcUrls = {
    321: "https://rpc-mainnet.kcc.network",
    // 322: "https://rpc-testnet.kcc.network"
}

const blockExplorers = {
    "kcc": 'https://explorer.kcc.io/',
    // "kcctest": "https://scan-testnet.kcc.network/"
}

const mdexUrls = {
    'FHT': 'https://info.mdex.me/#/pair/0x55542f696a3fecae1c937bd2e777b130587cfd2d',
    'FHUSD': 'https://info.mdex.me/#/pair/0x7964e55bbdaecde48c2c8ef86e433ed47fecb519',
    'FELA': 'https://info.mdex.me/#/pair/0xa1c540cfa848928299cdf309a251ebbaf666ce64',
    'HMDX': 'https://info.mdex.me/#/pair/0x1c85dD9E5FeE4c40786bd6278255D977946A364b',
    'FBUSD_b': 'https://swap.mimo.exchange/#/add/0x32085b8ea854529178bd0f4e92d3fd2475a3a159/0x84ABcb2832bE606341a50128AEB1DB43AA017449',
    'FWIOTX': 'https://swap.mimo.exchange/#/add/0x32085B8ea854529178bD0F4E92D3fd2475A3A159/0xA00744882684C3e4747faEFD68D283eA44099D03',
    'FILDA': 'https://swap.mimo.exchange/#/swap?inputCurrency=0x84abcb2832be606341a50128aeb1db43aa017449&outputCurrency=0x32085b8ea854529178bd0f4e92d3fd2475a3a159',
    'ELK': 'https://app.elk.finance/#/swap',
    'FWELA': 'https://glidefinance.io/add/0x00E71352c91Ff5B820ab4dF08bb47653Db4e32C0/ELA'
}

const useChainIdMap = {
    "321": "kcc",
    // "322": "kcctest"
}

//Only include the networks that are supported.
//Example: COMP contract is not available for Rinkeby. So dont't include that in this list.
//Networks not included in this list are categorized as "unsupported" networks
const chainIdMap = {
    "321": "kcc",
    // "322": "kcctest"
}

const markets = {
    "KCS": {
        "name": "KCS",
        "symbol": "KCS",
        "logo": kcs,
        "qToken": {
            "name": "Torches KCS",
            "symbol": "tKCS",
            "contract": "tKCS",
            "ABI": contractABI.qETH,
            "network": {
                "kcctest": {
                    "address": "0xDC9bB1465B9Eb66cD05a4964f2721559894529B1"
                },
                "kcc": {
                    "address": "0xf9401F5246185eD3Fd0EF48f4775250d32069AEf"
                }
            }
        }
    },
    "sKCS": {
        "name": "sKCS",
        "symbol": "sKCS",
        "ABI": contractABI.ERC20,
        "network": {
            "kcctest": {
                "address": "0x311dD61dF0E88dDc6803e7353F5d9B71522AedA9"
            },
            "kcc": {
                "address": "0x00eE2d494258D6C5A30d6B6472A09b27121Ef451"
            },
        },
        "logo": skcs,
        "qToken": {
            "name": "Torches sKCS",
            "symbol": "tSKCS",
            "contract": "QErc20",
            "ABI": contractABI.qERC20,
            "network": {
                "kcctest": {
                    "address": "0xE3A92f9113cDD652Ea3AD0A57835b41e8b88dAff"
                },
                "kcc": {
                    "address": "0x0868713842d2e296CeF26c86d736AC7C374A5199"
                },
            }
        }
    },
    "USDT": {
        "name": "USDT",
        "symbol": "USDT",
        "ABI": contractABI.ERC20,
        "network": {
            "kcctest": {
                "address": "0x67f6a7BbE0da067A747C6b2bEdF8aBBF7D6f60dc"
            },
            "kcc": {
                "address": "0x0039f574ee5cc39bdd162e9a88e3eb1f111baf48"
            }
        },
        "logo": usdt,
        "qToken": {
            "name": "Torches USDT",
            "symbol": "tUSDT",
            "contract": "QErc20",
            "ABI": contractABI.qERC20,
            "network": {
                "kcctest": {
                    "address": "0xfC49791fF96187ad3260f47CeDdB9c440f25cB6E"
                },
                "kcc": {
                    "address": "0x92dBEA1Ac6278a0b4AEC11388C94F8fAFBE246C1"
                },
            }
        }
    },
    "USDC": {
        "uiName": "USD Coin",
        "name": "USDC",
        "symbol": "USDC",
        "ABI": contractABI.ERC20,
        "network": {
            "kcc": {
                "address": "0x980a5afef3d17ad98635f6c5aebcbaeded3c3430"
            },
            "kcctest": {
                "address": "0xD6c7E27a598714c2226404Eb054e0c074C906Fc9"
            },
        },
        "logo": usdc,
        "qToken": {
            "name": "Filda USDC",
            "symbol": "tUSDC",
            "contract": "QErc20",
            "ABI": contractABI.qERC20,
            "network": {
                "kcc": {
                    "address": "0x23eBfcC4aB0Ddf47E7b99B1eb1B9eA790f3d646D"
                },
                "kcctest": {
                    "address": "0x3d41B01a94cd8538099005565b38de558CAE5EDA"
                }
            }
        }
    },
    // "BTCK": {
    //     "uiName": "Bitcoin",
    //     "name": "BTCK",
    //     "symbol": "BTCK",
    //     "ABI": contractABI.ERC20,
    //     disabled: true,
    //     "network": {
    //         "kcc": {
    //             "address": "0xfa93c12cd345c658bc4644d1d4e1b9615952258c"
    //         },
    //         "kcctest": {
    //             "address": "0xf57a7ee19a628e4d475b72d6c9dd847c50636e01"
    //         }
    //     },
    //     "logo": hbtc,
    //     "qToken": {
    //         "name": "tBTCK",
    //         "symbol": "tBTCK",
    //         "contract": "QErc20",
    //         "ABI": contractABI.qERC20,
    //         "network": {
    //             "kcc": {
    //                 "address": "0x413170815cF21B16ddeB180aAd0FFD00C80D31f8"
    //             },
    //             "kcctest": {
    //                 "address": "0xDBdAf501d752ef1CB408059D01D05Db3A1811632"
    //             }
    //         }
    //     }
    // },
    "WETH": {
        "uiName": "Ethereum",
        "name": "ETH",
        "symbol": "WETH",
        "uiSymbol": "ETH",
        "ABI": contractABI.ERC20,
        "network": {
            "kcc": {
                "address": "0xf55af137a98607f7ed2efefa4cd2dfe70e4253b1"
            },
            "kcctest": {
                "address": "0xf8cb9f1d136ff4c883320b5b4fa80048b888f459"
            }
        },
        "logo": heth,
        "qToken": {
            "name": "Filda ETH",
            "symbol": "tETH",
            "contract": "QErc20",
            "ABI": contractABI.qERC20,
            "network": {
                "kcc": {
                    "address": "0xF7310bD46D48e8d2BF8d12f69B6cCdD12fB4E0C5"
                },
                "kcctest": {
                    "address": "0x899A2189fB04B3Ed69B568f31D6505340A8910EB"
                }
            }
        }
    },
    "DAI": {
        "uiName": "Dai",
        "uiSymbol": "DAI",
        "name": "DAI",
        "symbol": "DAI",
        "ABI": contractABI.ERC20,
        "network": {
            "heco": {
                "address": "0x3d760a45d0887dfd89a2f5385a236b29cb46ed2a"
            },
            matic: {
                "address": "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063"
            },
            arbitrum: {
                address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1"
            }
        },
        "logo": dai,
        "qToken": {
            "name": "Filda DAI",
            "symbol": "qDAI",
            "contract": "QErc20",
            "ABI": contractABI.qERC20,
            "network": {
                "heco": {
                    "address": "0x3D7a2A68D00F117e9c3cFCfA9c1c1f73cB52baFc"
                },
                "matic": {
                    "address": "0x0Ab290A1EBF33F21d7c5d0F9B4CEE940921FDfC0"
                },
                arbitrum: {
                    address: "0x56c0fA757820C2d9Df35CF2874F3268FE717e92f"
                }
            }
        }
    },
    "AAVE": {
        "uiName": "Aave",
        "name": "AAVE",
        "symbol": "AAVE",
        "ABI": contractABI.ERC20,
        "network": {
            "heco": {
                "address": "0x202b4936fe1a82a4965220860ae46d7d3939bb25"
            },
            "matic": {
                "address": "0xD6DF932A45C0f255f85145f286eA0b292B21C90B"
            }
        },
        "logo": aave,
        "qToken": {
            "name": "Filda AAVE on Heco",
            "symbol": "fAAVE",
            "contract": "QErc20",
            "ABI": contractABI.qERC20,
            "network": {
                "heco": {
                    "address": "0x73Fa2931e060F7d43eE554fd1De7F61115fE1751"
                },
                "matic": {
                    "address": "0xb9542aDd6d6049Ae59Ce39e45Fb2EC88797931E7"
                }
            }
        }
    },
    "UNI": {
        "uiName": "Uniswap",
        "uiSymbol": "UNI",
        "name": "UNI",
        "symbol": "UNI",
        "ABI": contractABI.ERC20,
        "network": {
            "heco": {
                "address": "0x22c54ce8321a4015740ee1109d9cbc25815c46e6"
            },
            arbitrum: {
                "address": "0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0"
            }
        },
        "logo": uni,
        "qToken": {
            "name": "Filda UNI",
            "symbol": "fUNI",
            "contract": "QErc20",
            "ABI": contractABI.qERC20,
            "network": {
                "heco": {
                    "address": "0xAc9E3AE0C188eb583785246Fef37AEF9ea159fb7"
                },
                arbitrum: {
                    address: "0xcA7D7F202894e851e495beBCD2A62E0898dD1814"
                }
            }
        }
    },
    "SUSHI": {
        "uiName": "SushiSwap",
        "uiSymbol": "Sushi",
        "name": "SUSHI",
        "symbol": "SUSHI",
        "ABI": contractABI.ERC20,
        "network": {
            arbitrum: {
                address: "0xd4d42F0b6DEF4CE0383636770eF773390d85c61A"
            }
        },
        "logo": sushi,
        "qToken": {
            "name": "Filda SUSHI",
            "symbol": "fSUSHI",
            "contract": "QErc20",
            "ABI": contractABI.qERC20,
            "network": {
                arbitrum: {
                    "address": "0xDC17Ee4Ef70317433d8083dA696E63b46721b6B9"
                }
            }
        }
    },
    "SNX": {
        "uiName": "Synthetix",
        "uiSymbol": "HSNX",
        "name": "SNX",
        "symbol": "SNX",
        "ABI": contractABI.ERC20,
        "network": {
            "heco": {
                "address": "0x777850281719d5a96c29812ab72f822e0e09f3da"
            }
        },
        "logo": snx,
        "qToken": {
            "name": "Filda SNX on Heco",
            "symbol": "fSNX",
            "contract": "QErc20",
            "ABI": contractABI.qERC20,
            "network": {
                "heco": {
                    "address": "0x88962975FDE8C7805fE0f38b7c91C18f4d55bb40"
                }
            }
        }
    },
    "LINK": {
        "uiName": "Chainlink",
        "uiSymbol": "LINK",
        "name": "LINK",
        "symbol": "LINK",
        "ABI": contractABI.ERC20,
        "network": {
            "heco": {
                "address": "0x9e004545c59d359f6b7bfb06a26390b087717b42"
            },
            arbitrum: {
                address: "0xf97f4df75117a78c1A5a0DBb814Af92458539FB4"
            }
        },
        "logo": link,
        "qToken": {
            "name": "Filda LINK",
            "symbol": "qLINK",
            "contract": "QErc20",
            "ABI": contractABI.qERC20,
            "network": {
                "heco": {
                    "address": "0x9E6f8357bae44C01ae69df807208c3f5E435BbeD"
                },
                arbitrum: {
                    address: "0xeA1Ca194fF0d211F86fB8E9D8BE985e35Cd16968"
                }
            }
        }
    },
    "WKCS-WETH": {
        "isLPToken": true,
        "uiName": "WKCS-WETH",
        "uiSymbol": "WKCS-WETH",
        "name": "WKCS-WETH",
        "symbol": "WKCS-WETH",
        "ABI": contractABI.ERC20,
        "network": {
            "kcctest": {
                "address": "0x7c80A5F68F3cc8CeA2014510F093f8EB8c577Acb",
                decimals: 18
            }
        },
        "logo1": kcs,
        "logo2": heth,
        "qToken": {
            "name": "Filda WKCS-WETH",
            "symbol": "tWKCS-WETHLP",
            "contract": "QErc20",
            "ABI": contractABI.qERC20,
            "network": {
                "kcctest": {
                    "address": "0x2382F36c496d587811E2cE78DD72A395a4099C83",
                    decimals: 18
                }
            }
        },
        lpRewardsTokens: [
            {
                "name": "MojitoToken",
                "symbol": "MJT",
                "uiSymbol": "MJT",
                "logo": null,
                decimals: 18,
                "network": {
                    "kcctest": {
                        "address": "0x208EecDBc49C137D0174B848DEf5F8cB74d6951E"
                    }
                }
            },
            {
                "name": "KCC-Peg Tether USD",
                "symbol": "USDT",
                "uiSymbol": "USDT",
                "logo": usdt,
                decimals: 18,
                "network": {
                    "kcctest": {
                        "address": "0x67f6a7BbE0da067A747C6b2bEdF8aBBF7D6f60dc"
                    }
                }
            }
        ]
    },
    "USDC-USDT": {
        "isLPToken": true,
        "uiName": "USDC-USDT",
        "uiSymbol": "USDC-USDT",
        "name": "USDC-USDT",
        "symbol": "USDC-USDT",
        "ABI": contractABI.ERC20,
        "network": {
            "kcc": {
                "address": "0xeb06057e2405c8819e2cffea5dea07a54ad569e3",
                decimals: 18
            }
        },
        "logo1": usdc,
        "logo2": usdt,
        "qToken": {
            "name": "Torches USDC-USDT",
            "symbol": "tUSDC-USDT",
            "contract": "QErc20",
            "ABI": contractABI.qERC20,
            "network": {
                "kcc": {
                    "address": "0x3D6bFA610cF599197d0002bdFe83DfE77D34F73f",
                    decimals: 18
                }
            }
        },
        lpRewardsTokens: [{
            "name": "MojitoToken",
            "symbol": "MJT",
            "uiSymbol": "MJT",
            "logo": null,
            decimals: 18,
            "network": {
                "kcctest": {
                    "address": "0x208EecDBc49C137D0174B848DEf5F8cB74d6951E"
                },
                "kcc": {
                    "address": "0x2cA48b4eeA5A731c2B54e7C3944DBDB87c0CFB6F"
                }
            }
        }]
    }
}

//price feed contract not deployed on rinkeby
//We will be using an arbitrary price value
const priceOracle = {
    "ABI": contractABI.PriceOracle,
    "network": {
        "kcc": {
            "address": "0x935b755eFEcF62C222Bd0161E7981De7bF9319c0"
        },
        "kcctest": {
            "address": "0xfbAFd34A4644DC4f7c5b2Ae150279162Eb2B0dF6"
        }
    }
}

const comptroller = {
    "ABI": contractABI.Comptroller,
    "network": {
        kcc: {
            address: "0xfbAFd34A4644DC4f7c5b2Ae150279162Eb2B0dF6"
        },
        kcctest: {
            address: "0xf431ABdf916a370dB31Bb97EF74655EfDd80bceF"
        }

    }
}

const COMP = {
    decimals: 18,
    "ABI": contractABI.COMP,
    "network": {
        "kcc": {
            "address": "0x899A2189fB04B3Ed69B568f31D6505340A8910EB"
        },
        "kcctest": {
            "address": "0xFF008682bB67F48866dE72A433508c5a6897eE3c"
        }
    }
}

const governorAlpha = {
    "ABI": contractABI.GovernorAlpha,
    "network": {
        "main": {
            "address": "0xc0dA01a04C3f3E0be433606045bB7017A7323E38"
        },
        "ropsten": {
            "address": "0x93ACbA9ecaCeC21BFA09b0C4650Be3596713d747"
        }
    }
}

const compoundLens = {
    "ABI": contractABI.CompoundLens,
    "network": {
        kcc: {
            // address: "0x5B0Ad1D8d438B00b0730134c8F0BaEf3ed445a0A"
            address: "0x16962128f7b1ca922114878ff35548f9ac8727e7"
        },
        kcctest: {
            address: "0xfD05d30cB597e73e267bd9488303BBa8F1a19D85"
        }

    }
}

const maximillion = {
    "ABI": contractABI.Maximillion,
    "network": {
        kcc: {
            address: "0x5D9048Db9EeDc0A2FaDF1f55D41F7990d4eF7264"
        },
        kcctest: {
            address: "0x22095dF5457F6D3472c0326fEe32785c2a568AE2"
        }
    }
}

const poolManager = {
    "ABI": contractABI.PoolManager,
    "network": {
        "elatest": {
            "address": "0x4cE5B72361262F852b434C8257EE079E7dD10bC7"
        },
        "heco": {
            "address": "0x0492E6060e71F5bED30B35D5238934066e31Bfc9"
        }
    }
}

const noMintRewardPool = {
    ABI: contractABI.NoMintRewardPool,
    dividendERC20ABI: contractABI.DividendERC20
}

const interestRateModel = {
    "ABI": contractABI.InterestRateModel
}

const mdex = {
    "ABI": contractABI.hecoPool,
    "address": "0xFB03e11D93632D97a8981158A632Dd5986F5E909",
    "factory": "0xb0b670fc1f7724119963018db0bfa86adb22d941",
    "hecoPoolPair": contractABI.hecoPoolPair,
    "reward": {
        "name": "MDX Token",
        "symbol": "MDX",
        "address": "0x25d2e80cb6b86881fd7e07dd263fb79f4abe033c"
    }
}

const uniswapPair = {
    "ABI": contractABI.IUniswapV2Pair,
    "network": {
        "kcctest": {
            filda: {
                "address": "0x481cb63fE6D84bd5050dF097199b519776078769",
                "token": "reserve1",
                "stablecoin": "reserve0",
                "shift": 0
            }
        }
    }
}

const multiCall = {
    "network": {
        kcc: {
            address: "0x90eee4d26c13b66088Aa5066A123fc52ED84eC44"
        },
        kcctest: {
            address: "0x665683D9bd41C09cF38c3956c926D9924F1ADa97"
        }
    }
}

const erc20 = {
    "ABI": contractABI.ERC20
}

//These are temporary testnet addresses. Ideally these lists should be fetched from pool manager
const pools = {
}

const voteProposal = {
    "ABI": contractABI.voteProposal,
    "MultipleABI": contractABI.voteMultiProposal,
    "network": {
        "heco":
            [{ "address": "0x39EebeD78817Fad1fA891a5840FfC71619efFEF7" },
            { "address": "0x92d737DC7d6141416768949596a7ABBd2ae246Fd" },
            { "address": "0xaFfD84fb3C1B2e3eD88d07300F1b3bAF8D18906a" },
            { "address": "0x20771E1BC3bF598FEa8a6A992Bda817a9E8de8dB" },
            { "address": "0xcd5DEaaB1a75F6939E3e1E6E87A44b488ffd17B9" },
            {
                "address": "0x2Fab287f8F3e223e8440044Eb44d45452423cD5f",
                isMultiple: true
            },
            { "address": "0x758b49B5d7c7a58a6368a9f11A0aD8e804b81189" },
            { "address": "0xf2A9A50E94bDeC234a3CAd96c9f796d5724276E9" },
            { "address": "0x144084F43CfC53bfAE7A6BeBC6F059dba1e06c54" },
            { "address": "0xAfb5dAe50D573627caFc78bb0eB3b95679D74c75" },
            { "address": "0xe7048442A5035bc43fF4be5Ae91287869C61Ee1D" }
            ]
    }
}

const errorCodes = {
    "0": {
        "name": "NO_ERROR",
        "desc": "Not a failure."
    },
    "1": {
        "name": "UNAUTHORIZED",
        "desc": "The sender is not authorized to perform this action."
    },
    "2": {
        "name": "BAD_INPUT",
        "desc": "An invalid argument was supplied by the caller."
    },
    "3": {
        "name": "COMPTROLLER_REJECTION",
        "desc": "The action would violate the comptroller policy."
    },
    "4": {
        "name": "COMPTROLLER_CALCULATION_ERROR",
        "desc": "An internal calculation has failed in the comptroller."
    },
    "5": {
        "name": "INTEREST_RATE_MODEL_ERROR",
        "desc": "The interest rate model returned an invalid value."
    },
    "6": {
        "name": "INVALID_ACCOUNT_PAIR",
        "desc": "The specified combination of accounts is invalid."
    },
    "7": {
        "name": "INVALID_CLOSE_AMOUNT_REQUESTED",
        "desc": "The amount to liquidate is invalid."
    },
    "8": {
        "name": "INVALID_COLLATERAL_FACTOR",
        "desc": "The collateral factor is invalid."
    },
    "9": {
        "name": "MATH_ERROR",
        "desc": "A math calculation error occurred."
    },
    "10": {
        "name": "MARKET_NOT_FRESH",
        "desc": "Interest has not been properly accrued."
    },
    "11": {
        "name": "MARKET_NOT_LISTED",
        "desc": "The market is not currently listed by its comptroller."
    },
    "12": {
        "name": "TOKEN_INSUFFICIENT_ALLOWANCE",
        "desc": "ERC-20 contract must allow Money Market contract to call transferFrom. The current allowance is either 0 or less than the requested supply, repayBorrow or liquidate amount."
    },
    "13": {
        "name": "TOKEN_INSUFFICIENT_BALANCE",
        "desc": "Caller does not have sufficient balance in the ERC-20 contract to complete the desired action."
    },
    "14": {
        "name": "TOKEN_INSUFFICIENT_CASH",
        "desc": "The market does not have a sufficient cash balance to complete the transaction. You may attempt this transaction again later."
    },
    "15": {
        "name": "TOKEN_TRANSFER_IN_FAILED",
        "desc": "Failure in ERC-20 when transfering token into the market."
    },
    "16": {
        "name": "TOKEN_TRANSFER_OUT_FAILED",
        "desc": "Failure in ERC-20 when transfering token out of the market."
    }
}

const WNATIVE = {
    symbol: ["HT", "Matic", "IOTX", "ELA"],
    network: {
        heco: "0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f",
        matic: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
        IoTeX: "0xa00744882684c3e4747faefd68d283ea44099d03",
        elamain: "0x517E9e5d46C1EA8aB6f78677d6114Ef47F71f6c4"
    }
}
// 换币还款
const SwapRepayContract = {
    heco: "0xE1A3B85686920aa0450F36f06efBC21050d15C55",
    matic: "0x2E6A312129989ee69e1FFeFB6C549A6fc3F59e91",
    IoTeX: "0xD66CF7735a828763DC2d851696B0Fff86c41863A",
    elamain: "0x28347DA75a26955995738B6dB54aEAa6321a807d",
    kcctest: "0x28347DA75a26955995738B6dB54aEAa6321a807d",
}
const swapRouter = {
    heco: "0xED7d5F38C79115ca12fe6C0041abb22F0A06C300",
    matic: "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
    IoTeX: "0x95cb18889b968ababb9104f30af5b310bd007fd8",
    elamain: "0xec2f2b94465Ee0a7436beB4E38FC8Cf631ECf7DF"
}
const LiquidateContract = {
    heco: "0x67f9FE06B109559602250b89845BBD792349E939"
}
const DepositSwapContract = { // 存款交换
    heco: "0xEbc6Ed7Cc09907C6331Ab01146dfAdA1fAFfD356",
    matic: "0x2B60E9a8876F0d5586320D1d3b6aC65b41Ebb9b2",
    IoTeX: "0x132804EdAD9c01D9d27905078fa2Ca3CD21C9cDD",
    elamain: "0xba24a083a88B93b46604BE05fBD90e43a6f1c181",
    kcctest: "0xba24a083a88B93b46604BE05fBD90e43a6f1c181",
}
const FlashLoanContract = "0x0078E7712Af18997E7aA3FdB48Cd97F6b8077831" // 只有heco中使用

const DepositRepayContract = { // 存款还借款
    heco: "0x7fb6a4Ebe618eC24CcaD635c79438436429aC5aE",
    matic: "0xf88a6D48cbAe965BF49F4453a6560698F5478fdD",
    IoTeX: "0x22831DFFbC91660E87FBbAfe3EB38A401958A477",
    elamain: "0x659b17b9DD16a219f737bF586d03C2DFaEa8bB6b"
}
const FeeManagerContract = {
    heco: "0x319EBfD62dD279297582ea06d34aB514d8C55e07",
    matic: "0x3377BCA3FFC6fca2d77F6Bf3E9EB5BC6dD88aBD4",
    IoTeX: "0x0a8f6F0FC8a3C0935fd517B4c241897eF853D4d0",
    elamain: "0x840a28D68d5305b81420B1B8d1236239fa175c97"
}

const qsConfigAddress = {
    kcc: "0x633DB50BF9F968b0A4a4E212D8675Ad251C9c628",
    kcctest: "0xF7310bD46D48e8d2BF8d12f69B6cCdD12fB4E0C5"
}

const dQuick = "0xf28164a485b0b2c90639e47b0f377b4a438a16b1"

const QsPriceOracleForElaAddress = "0x5117b046517ffa18d4d9897090d0537ff62a844a"

const apiUrls = {
    "allMarkets": "https://us.filda.io/data/heco/allmarkets.json",
    "accountsLiquidity": "https://lq.ifoobar.com/api/accountsliquidity?status=shortfall",
    "accountDetail": "https://lq.ifoobar.com/api/account"
}

const walletConnect = {
    rpc: rpcUrls,
    //bridge: "http://192.168.31.114:5001"
    bridge: "https://bridge.walletconnect.org",

    // Good wallet integrations such as metamask mobile are able to detect if we don't send
    // a chainId manually and automatically return the network selected by users in the wallet.
    // Though, most other wallets such as TokenPocket consider that if no chainId is given by us,
    // they simply return chainId 1 (ethereum mainnet) which is not what we want.
    // Our solution is therefore to force the chainId to HECO for now.
    chainId: 321,

    clientMeta: {
        name: "torches.io",
        description: "Torches - A DeFi app powered by Torches Team",
        url: "https://www.torches.finance",
        icons: [
            "https://www.torches.finance/favicon.png"
        ]
    }
}

const thirdMarkets = {
    "PTD": {
        "url": "https://p.td",
        "data": "https://p.td/api/public",
    },
    "Booster": {
        network: {
            "heco": "0xa61A4F9275eF62d2C076B0933F8A9418CeC8c670"
        }
    }
}

const LanguageList = [
    {
        key: "en",
        name: "English",
    },
    // {
    //     key: "ru",
    //     name: "русский",
    // },
    {
        key: "zh-TW",
        name: "繁體中文",
    }
]

const exchangeTitles = {
    sushiSwap: 0,
    quickSwap: 1,
    paraSwap: 2
}

const exchanges = [
    { id: exchangeTitles.sushiSwap, title: "SUSHISWAP", icon: "https://www.sushi.com/static/media/logo.dec926df.png" },
    { id: exchangeTitles.quickSwap, title: "QUICKSWAP", icon: "/images/quicklogo.jpg" }
];

const liquidateService = {
    // https://api.torches.finance/accounts/torches/kcc?health=1000
    // host: 'https://api.torches.finance/accounts/torches'
    host: 'https://data.torches.finance/accounts/torches'
}

const leverageService = {
    // https://api.torches.finance/homorabank/filda/arbitrum?account=0x...
    // host: "https://api.torches.finance/homorabank/filda/"
    host: "https://data.torches.finance/homorabank/filda/"
}

const leveragePools = {
    heco: {},
    hecotest: {},
    arbitrum: {
        available: true,
        ChainlinkAdapterOracle: "0x84A59f029B56D502210bCf6B88594c0Ee7B9E479",
        CoreOracle: {
            address: "0x6C57aB517F66b0C0C4D1FD840a65865218bdC944",
            ABI: "/abis/CoreOracle.json"
        },
        UniswapV2Oracle: {
            address: "0xCCe1B24C2FE9c820c039D37a3e7138a8B72FF047",
            ABI: "/abis/UniswapV2Oracle.json"
        },
        ProxyOracle: {
            address: "0xa63C1b10a3fF1191998e153b57AD9DE322Cde5f8",
            ABI: "/abis/ProxyOracle.json"
        },
        HomoraBankProxy: {
            address: "0x8d2d7a730B4d4ac817A0495e435f287B89ca32da",
            ABI: "/abis/HomoraBank.json"
        },
        Spell: {
            address: "0x3745A5A808734D9bC2b2c8dF0f72910BF7F50b25",
            ABI: "/abis/SushiswapSpellV2.json"
        },
        WMiniChef: {
            address: "0xF7aafe77100a853599020c1BcaE048607aF55Db5",
            ABI: "/abis/IWMiniChefV2.json",
        },
        cErc20AdaptorInstanceForETH: "0xA1DCBC023dCcC2AdE4dccd68593E63C56Be2cD00",
        denomination: 10000,
        cryptoDecimals: 18,
        pairs: {
            "ETH-USDT": {
                title: "ETH-USDT",
                pid: 4,
                available: true,
                exchange: exchangeTitles.sushiSwap,
                feesRate: 0.00076,
                leverageTimes: 5,
                tokenA: {
                    symbol: "ETH",
                    logo: eth,
                    address: "0x0000000000000000000000000000000000000000",
                    ABI: "/abis/ERC20.json",
                    decimals: 18,
                    wrapped: {
                        address: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
                        ABI: "/abis/ERC20.json"
                    }
                },
                tokenB: {
                    symbol: "USDT",
                    logo: usdt,
                    address: "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
                    ABI: "/abis/ERC20.json",
                    decimals: 6
                },
                staking: {
                    address: "0xF4d73326C13a4Fc5FD7A064217e12780e9Bd62c3",
                    ABI: "/abis/MiniChefV2.json"
                },
                WStakingRewards: {
                    address: "0xF7aafe77100a853599020c1BcaE048607aF55Db5",
                    ABI: "/abis/IWMiniChefV2.json",
                },
                lp: {
                    symbol: "SLP",
                    address: "0xcb0e5bfa72bbb4d16ab5aa0c60601c438f04b4ad",
                    decimals: 18,
                    ABI: "/abis/SushiSwapPairMock.json"
                },
                reward: {
                    address: "0xd4d42F0b6DEF4CE0383636770eF773390d85c61A",
                    ABI: "/abis/ERC20.json",
                    logo: "/images/sushi.png",
                    decimals: 18,
                    pairForPrice: {
                        address: "0xBdd6c6A2C34B27A17Eb4b884afb72277451DaE6E",
                        ABI: "/abis/SushiSwapPairMock.json",
                        decimalsAnother: 6
                    },
                },
            },
            "ETH-USDC": {
                title: "ETH-USDC",
                pid: 0,
                available: true,
                exchange: exchangeTitles.sushiSwap,
                feesRate: 0.00095,
                leverageTimes: 5,
                tokenA: {
                    symbol: "ETH",
                    logo: eth,
                    address: "0x0000000000000000000000000000000000000000",
                    ABI: "/abis/ERC20.json",
                    decimals: 18,
                    wrapped: {
                        address: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
                        ABI: "/abis/ERC20.json"
                    }
                },
                tokenB: {
                    symbol: "USDC",
                    logo: usdc,
                    address: "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
                    ABI: "/abis/ERC20.json",
                    decimals: 6
                },
                staking: {
                    address: "0xF4d73326C13a4Fc5FD7A064217e12780e9Bd62c3",
                    ABI: "/abis/MiniChefV2.json"
                },
                WStakingRewards: {
                    address: "0xF7aafe77100a853599020c1BcaE048607aF55Db5",
                    ABI: "/abis/IWMiniChefV2.json",
                },
                lp: {
                    symbol: "SLP",
                    address: "0x905dfCD5649217c42684f23958568e533C711Aa3",
                    decimals: 18,
                    ABI: "/abis/SushiSwapPairMock.json"
                },
                reward: {
                    address: "0xd4d42F0b6DEF4CE0383636770eF773390d85c61A",
                    ABI: "/abis/ERC20.json",
                    decimals: 18,
                    logo: "/images/sushi.png",
                    pairForPrice: {
                        address: "0xBdd6c6A2C34B27A17Eb4b884afb72277451DaE6E",
                        ABI: "/abis/SushiSwapPairMock.json",
                        decimalsAnother: 6
                    },
                },
            },
            "ETH-DAI": {
                title: "ETH-DAI",
                pid: 14,
                available: true,
                exchange: exchangeTitles.sushiSwap,
                feesRate: 0.00027,
                leverageTimes: 5,
                tokenA: {
                    symbol: "ETH",
                    logo: eth,
                    address: "0x0000000000000000000000000000000000000000",
                    ABI: "/abis/ERC20.json",
                    decimals: 18,
                    wrapped: {
                        address: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
                        ABI: "/abis/ERC20.json"
                    }
                },
                tokenB: {
                    symbol: "DAI",
                    logo: dai,
                    address: "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1",
                    ABI: "/abis/ERC20.json",
                    decimals: 18
                },
                staking: {
                    address: "0xF4d73326C13a4Fc5FD7A064217e12780e9Bd62c3",
                    ABI: "/abis/MiniChefV2.json"
                },
                WStakingRewards: {
                    address: "0xF7aafe77100a853599020c1BcaE048607aF55Db5",
                    ABI: "/abis/IWMiniChefV2.json",
                },
                lp: {
                    symbol: "SLP",
                    address: "0x692a0B300366D1042679397e40f3d2cb4b8F7D30",
                    decimals: 18,
                    ABI: "/abis/SushiSwapPairMock.json"
                },
                reward: {
                    address: "0xd4d42F0b6DEF4CE0383636770eF773390d85c61A",
                    ABI: "/abis/ERC20.json",
                    decimals: 18,
                    logo: "/images/sushi.png",
                    pairForPrice: {
                        address: "0xBdd6c6A2C34B27A17Eb4b884afb72277451DaE6E",
                        ABI: "/abis/SushiSwapPairMock.json",
                        decimalsAnother: 6
                    },
                },
            }
        }
    },
    matic: {
        available: false,
        ChainlinkAdapterOracle: "0xa258bfFD5F6814303a718d2B9D34B40E14C66289",
        CoreOracle: {
            address: "0x71180944e4288d9191Bc3A2dF94f80ba06D01eC3",
            ABI: "/abis/CoreOracle.json"
        },
        UniswapV2Oracle: {
            address: "0xFD0c6b70C4dA159F24Ffa64f209CfdbbA9595A79",
            ABI: "/abis/UniswapV2Oracle.json"
        },
        ProxyOracle: {
            address: "0x1B5eC540A4BA9db5655F36e8F3372C5551DA9fb6",
            ABI: "/abis/ProxyOracle.json"
        },
        HomoraBankProxy: {
            address: "0xAeE67519049092AB91EFD033f7d350D62b9f166B",
            ABI: "/abis/HomoraBank.json"
        },
        Spell: {
            address: "0x940913158A59a7aE71C76A6D09fc75E957050442",
            ABI: "/abis/UniswapV2SpellV1.json"
        },
        cErc20AdaptorInstanceForETH: "0x28347DA75a26955995738B6dB54aEAa6321a807d",
        denomination: 10000,
        cryptoDecimals: 18,
        pairs: {
            "ETH-MATIC": {
                title: "ETH-MATIC",
                available: true,
                tokenA: {
                    symbol: "Matic",
                    logo: hmatic,
                    address: "0x0000000000000000000000000000000000000000",
                    ABI: "/abis/ERC20.json",
                    decimals: 18,
                    wrapped: {
                        address: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
                        ABI: "/abis/ERC20.json"
                    }
                },
                tokenB: {
                    symbol: "WETH",
                    logo: heth,
                    address: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
                    ABI: "/abis/ERC20.json",
                    decimals: 18
                },
                lp: {
                    symbol: "UNI-V2",
                    address: "0xadbF1854e5883eB8aa7BAf50705338739e558E5b",
                    decimals: 18,
                    ABI: "/abis/UniswapV2Pair.json"
                },
                factory: {
                    address: "0x5757371414417b8C6CAad45bAeF941aBc7d3Ab32",
                    ABI: "/abis/UniswapV2Factory.json"
                },
                WStakingRewards: {
                    address: "0xdB82850b0d28Fbc3e00DEd5b309cf13Fa0165cfb",
                    ABI: "/abis/ERC20.json"
                },
                StakingRewards: {
                    address: "0x8FF56b5325446aAe6EfBf006a4C1D88e4935a914",
                    ABI: "/abis/StakingRewards.json"
                },
                reward: {
                    address: "0x831753DD7087CaC61aB5644b308642cc1c33Dc13",
                    ABI: "/abis/Reward.json",
                    decimals: 18,
                    logo: "/images/quick.png",
                    pairForPrice: {
                        address: "0x7641F96A2B7b4708bc9E8F4D7ca08E232C31A3Dd",
                        ABI: "/abis/UniswapV2Pair.json",
                        decimalsAnother: 6
                    },
                    quickswapStakingRewards: {
                        address: "0x4b678cA360c5f53a2B0590e53079140F302A9DcD",
                        ABI: "/abis/StakingRewards.json"
                    }
                },
                exchange: exchangeTitles.quickSwap,
                leverageTimes: 5,
                feesRate: 0.0015
            },
            "WETH-USDT": {
                title: "WETH-USDT",
                available: true,
                tokenA: {
                    symbol: "WETH",
                    logo: heth,
                    address: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
                    ABI: "/abis/ERC20.json",
                    decimals: 18
                },
                tokenB: {
                    symbol: "USDT",
                    logo: usdt,
                    address: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
                    ABI: "/abis/ERC20.json",
                    decimals: 6
                },
                lp: {
                    symbol: "UNI-V2",
                    address: "0xF6422B997c7F54D1c6a6e103bcb1499EeA0a7046",
                    decimals: 18,
                    ABI: "/abis/UniswapV2Pair.json"
                },
                factory: {
                    address: "0x5757371414417b8C6CAad45bAeF941aBc7d3Ab32",
                    ABI: "/abis/UniswapV2Factory.json"
                },
                WStakingRewards: {
                    address: "0x3D58966A119AbAa6f61cb4656125dAeDFF069eC5",
                    ABI: "/abis/ERC20.json"
                },
                StakingRewards: {
                    address: "0xB26bfcD52D997211C13aE4C35E82ced65AF32A02",
                    ABI: "/abis/StakingRewards.json"
                },
                reward: {
                    address: "0x831753DD7087CaC61aB5644b308642cc1c33Dc13",
                    ABI: "/abis/Reward.json",
                    decimals: 18,
                    logo: "/images/quick.png",
                    pairForPrice: {
                        address: "0x7641F96A2B7b4708bc9E8F4D7ca08E232C31A3Dd",
                        ABI: "/abis/UniswapV2Pair.json",
                        decimalsAnother: 6
                    },
                    quickswapStakingRewards: {
                        address: "0x45a5CB25F3E3bFEe615F6da0731740093F59b768",
                        ABI: "/abis/StakingRewards.json"
                    }
                },
                exchange: exchangeTitles.quickSwap,
                leverageTimes: 5,
                feesRate: 0.0009
            },
        }
    }
}

const excludeDepositSwap = ["NEO", "HXTZ", "HBSV", "htELA", "HELA"]

const approvalMsgToken = {
    heco: 'HRC20',
    IoTeXTestnet: 'XRC20',
    IoTeX: 'XRC20',
    bsc: 'BEP20',
    bscTestnet: 'BEP20',
    elamain: 'ERC20'
}

const lpSwapName = {
    matic: 'QUICK',
    heco: 'MDEX',
    bsc: 'CAKE',
    IoTeX: 'MIMO',
    arbitrum: 'SUSHI',
    elamain: 'GLIDE',
    kcc: "MojitoSwap",
    kcctest: "MojitoSwap"
}

const swapUrl = {
    matic: [
        {
            name: "quickswap",
            url: "https://quickswap.exchange/"
        },
    ],
    heco: [
        {
            name: "mdex",
            url: "https://ht.mdex.com/"
        },
        {
            name: "iswap",
            url: "http://www.iswap.com?utm_channel=filda"
        },
        {
            name: "hswap",
            url: "https://www.hswap.com/#/swap"
        },
        {
            name: "Huobi Global",
            url: "http://huobi.com/"
        },
    ],
    IoTeX: [
        {
            name: "mimo",
            url: "https://mimo.exchange/#/swap"
        }
    ],
    IoTeXTestnet: [
        {
            name: "mimo",
            url: "https://mimo.exchange/#/swap"
        }
    ],
    bsc: [
        {
            name: "pancakeswap",
            url: "https://pancakeswap.finance/swap"
        },
        {
            name: "iswap",
            url: "http://www.iswap.com?utm_channel=filda"
        }
    ],
    elamain: [
        {
            name: "glide",
            url: "https://glidefinance.io/swap"
        }
    ],
}

// heco为3秒出个块，matic为2秒出个块
const blockInterval = {
    heco: 3,
    matic: 2,
    bsc: 3,
    IoTeX: 5,
    IoTeXTestnet: 5,
    arbitrum: 15,
    arbitrumtest: 15,
    elamain: 5,
    kcc: 3,
    kcctest: 3
}

// 头部钱包和链的配置
const headerConfigData = {
    wallet: {
        metamask: {
            name: 'MetaMask',
            img: wallerMetamask,
            modalImg: wallerMetamask,
            url: "https://metamask.io/download/"
        },
        walletconnect: {
            name: 'Wallet Connect',
            img: wallerWalletconnect,
            modalImg: wallerWalletconnect,
        },
        kucoinWallet: {
            name: 'Halo Wallet',
            img: wallerKucoinWallet,
            modalImg: wallerKucoinWallet,
            url: "https://halo.social/"
        },
        tokenPocket: {
            name: 'Token Pocket',
            img: wallerTokenPocket,
            modalImg: wallerTokenPocket,
            url: "https://www.tokenpocket.pro/"
        },
        bitKeep: {
            name: 'Bit Keep',
            img: wallerBitKeep,
            modalImg: wallerBitKeep,
            url: "https://bitkeep.com/download?type=0&theme=light"
        },
        unstoppabledomains: {
            name: 'Unstoppable Domains',
            img: unstoppableDomains,
            modalImg: unstoppableDomains
        },
        /** Other injected type wallet can be configured directly **/
    },
    network: {
        kcc: {
            name: 'KCC Mainnet',
            chain: 'KCC',
            chainAsKey: "kcc",
            img: networkKcc,
            modalImg: networkKcc,
            // 
            chainId: 321,
            chainName: 'KCC Mainnet',
            rpcUrls: [rpcUrls[321]],
            nativeCurrency: {
                name: 'KCS',
                symbol: 'KCS',
                decimals: 18
            },
            blockExplorerUrls: [blockExplorers.kcc],
            normalGasLimit: 44286
        },
        // kcctest: {
        //     name: 'KCC Testnet',
        //     chain: 'KCCTEST',
        //     chainAsKey: "kcctest",
        //     img: networkKcc,
        //     modalImg: networkKcc,
        //     // 
        //     chainId: 322,
        //     chainName: 'KCC Testnet',
        //     rpcUrls: [rpcUrls[322]],
        //     nativeCurrency: {
        //         name: 'tKCS',
        //         symbol: 'tKCS',
        //         decimals: 18
        //     },
        //     blockExplorerUrls: [blockExplorers.kcctest],
        //     normalGasLimit: 44286
        // }
    }
}

// liquidate V2 symbol的映射关系(markets对应不上的symbol)
const liquidateMarkets = {
    heco: {
        eth: "HETH",
        ela: "htELA",
        pneo: "NEO",
        whtusdt: "WHT-USDT",
        hbtcusdt: "HBTC-USDT",
        ethusdt: "ETH-USDT",
        ethhbtc: "ETH-HBTC"
    },
    IoTeX: {
        ["busd-bsc"]: "BUSD_b",
        iousdt: "USDT",
        iowbtc: "WBTC",
        iousdc: "USDC",
        ioeth: "WETH",
        iousdtwiotx: "ioUSDT-WIOTX",
        ciotxwiotx: "CIOTX-WIOTX",
        ioethwiotx: "ioETH-WIOTX"
    },
    matic: {
        matic: 'Matic',
        eth: 'WETH',
        quickusdcweth: 'ETH-USDC',
        quickwbtcweth: 'BTC-ETH',
        quickdualwmaticweth: 'MATIC-ETH',
        quickdualwmaticusdc: 'MATIC-USDC',
        quickdualwmaticusdt: 'MATIC-USDT',
        quickwethusdt: 'ETH-USDT',
    },
    bsc: {
        eth: 'BETH'
    },
    arbitrum: {
        eth: 'ETH'
    },
    elamain: {
        ela: 'ELA',
        eth: 'elaETH',
        fbnbbtcb: 'BTCB',
        ethusdc: 'USDC',
        bnbbusd: 'bnbBUSD',
        hthusd: 'HUSD'
    },
    kcc: {
        eth: 'WETH'
    }
}

const partners = [
    {
        url: "https://www.kcc.io/",
        logo: KCC,
        width: 131,
        height: 44.72,
        name: 'KCC'
    },
    {
        url: "#",
        logo: KucoinVentures,
        width: 160,
        height: 48,
        name: 'Kucoin Ventures'
    },
    {
        url: "https://kuwallet.com/",
        logo: KucoinWallet,
        width: 138,
        height: 49,
        name: 'Kucoin Wallet'
    },
    {
        url: "https://bitkeep.com/",
        logo: BitKeep,
        width: 165,
        height: 53,
        name: 'BitKeep'
    },
    {
        url: "https://www.coinhub.org/",
        logo: Coinhub,
        width: 166,
        height: 52,
        name: 'Coinhub'
    },
    {
        url: "https://coinmarketcap.com/currencies/torches/",
        logo: CoinMarketCap,
        width: 198,
        height: 34,
        name: 'CoinMarketCap'
    },
    {
        url: "https://www.mojitoswap.finance/",
        logo: MojitoSwap,
        width: 216,
        height: 40.8,
        name: 'MojitoSwap'
    },
    {
        url: "https://foxwallet.com/en",
        logo: FoxWallet,
        width: 203,
        height: 68,
        name: 'FoxWallet'
    },
    {
        url: "https://befiwalletdao.com/",
        logo: BefiWallet,
        width: 222,
        height: 47,
        name: 'Befi Wallet DAO'
    },
    {
        url: "https://kupay.finance/",
        logo: KuPay,
        width: 155,
        height: 51.83,
        name: 'KuPay'
    },
    {
        url: "https://defillama.com/protocol/torches",
        logo: Defillama,
        width: 158,
        height: 53,
        name: 'Defillama'
    },
    {
        url: "https://www.tokenpocket.pro/",
        logo: TokenPocket,
        width: 207,
        height: 27.86,
        name: 'Token Pocket'
    },
    {
        url: "https://mathwallet.org/en-us/",
        logo: Mathwallet,
        width: 244,
        height: 26,
        name: 'Math Wallet'
    }
]

const community = [
    {
        "name": "Twitter",
        "url": "https://twitter.com/TorchesFinance",
        "logo": Twitter,
        "width": 45,
        "height": 36
    },
    {
        "name": "Discord",
        "url": "https://discord.com/invite/DH5Z7qQyNR",
        // "url": "#",
        "logo": Discord,
        "width": 52,
        "height": 36
        // "target": "_self"
    },
    {
        "name": "Telegram",
        "url": "https://t.me/+e9oc0iyoIHJmNmI9",
        // "url": "#",
        "logo": Telegram,
        "width": 43,
        "height": 36
        // "target": "_self"
    },
    {
        "name": "Medium",
        "url": "https://medium.com/@TorchesFinance",
        "logo": Medium,
        "width": 56,
        "height": 32
    }
]

export const nftPageStartDate = '2022-07-09'

let navList = [
    {
        path: '/',
        name: 'Home',
        txt: 'Header.Nav.Home',
        sameDomain: true,
        headerShow: true,
    },
    {
        path: '/crypto-bank',
        name: 'Banking',
        txt: 'Header.Nav.Banking',
        sameDomain: true,
        headerShow: true,
        footerShow: true
    },
    // {
    //     path: '/nft',
    //     name: 'NFT',
    //     txt: 'Header.Nav.Nft',
    //     sameDomain: true,
    //     headerShow: true,
    //     footerShow: true,
    //     hot: false
    //     // startDate: nftPageStartDate
    // },
    // {
    //     path: '/lock',
    //     name: 'Lock',
    //     txt: 'lock',
    //     sameDomain: true,
    //     headerShow: true,
    //     footerShow: true,
    //     hot: true
    // },
    // {
    //     path: '/vote',
    //     name: 'vote',
    //     txt: 'DAO',
    //     sameDomain: true,
    //     headerShow: true,
    //     footerShow: true,
    //     hot: false
    // },
    {
        path: '/liquidation',
        name: 'Liquidation',
        txt: 'Header.Nav.Liquidation',
        sameDomain: true,
        footerShow: true,
    },
    {
        path: 'https://torches.gitbook.io',
        name: 'Docs',
        txt: 'Header.Nav.Docs',
        isBlank: true,
        headerShow: true,
    },
    {
        path: 'https://torches.gitbook.io/welcome-to-gitbook/guides/faqs',
        name: 'FAQ',
        txt: 'Header.Nav.FAQ',
        isBlank: true,
        headerShow: true,
    },
    {
        path: 'https://github.com/TorchesFinance/bug-bounty',
        name: 'BugBounty',
        txt: 'Header.Nav.BugBounty',
        isBlank: true,
        footerShow: true,
        startDate: '2022-06-29'
    },
    {
        path: "https://www.kcc.io/bridge/transfer/",
        name: 'Bridge',
        txt: 'Header.Nav.Bridge',
        isBlank: true,
        footerShow: true
    }
]

const torchlight = {
    ABI: "/abis/Torchlight.json",
    symbol: 'TOL',
    address: {
        kcc: "0x6d6a72741E3A413B98fEbB4D328eE86093eD7CE0",
        kcctest: "0x535E433b4579b910c5824746252182140B80d751"
    },
    baseURI: {
        kcc: "https://nft-info.torches.finance/collections/0x6d6a72741E3A413B98fEbB4D328eE86093eD7CE0/tokens/",
        kcctest: "https://t-nft-info.torches.finance/collections/0x535E433b4579b910c5824746252182140B80d751/tokens/",
    },
    deployAt: {
        kcc: 11576321,
        kcctest: 12588565
    },
    topics: {
        transfer: "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef"
    },
    airdropAPI: "https://airdrop.torches.finance/v1/airdrop/list",
    forge: {
        kcctest: {
            address: "0x5cFAdD8EBdf4b0D5B99e1f97b2d307aEA9A2eafa",
            ABI: "/abis/forge.json"
        },
        kcc: {
            address: "0x42d5F2aEc3F4949E4e730a3e6a649182817eA647",
            ABI: "/abis/forge.json"
        }
    },
    synthesizer: {
        kcctest: "https://t-nft-info.torches.finance/synthesize/:address/:hash",
        kcc: "https://nft-info.torches.finance/synthesize/:address/:hash"
    },
    forgingLogsAPI: {
        kcctest: "https://t-nft-info.torches.finance/synthesize/logs/last/200",
        kcc: "https://nft-info.torches.finance/synthesize/logs/last/200"
    }
};

const udAuthConfig = {
    clientID: "8cf926f3-6a09-4a9f-a16a-a077120827a7",
    // redirectUri: "http://localhost:7000",
    // redirectUri: "https://development.torches.finance",
    redirectUri: "https://torches.finance",
    scope: "openid wallet"
}

const veToken = {
    // kcctest: {
    //     blockTime: 3,
    //     rewardHelper: {
    //         address: "0xd4AdD656DF46Ca41D0402aABce779Aa9A52FDA11",
    //         ABI: "/abis/RewardHelper.json"
    //     },
    //     minter: {
    //         address: "0xaCE45cDD831eDE74bE61db30dfF42F1e5Ae89ef9",
    //         ABI: "/abis/minter.json"
    //     },
    //     voting_escrow: {
    //         address: "0x59bD8211971936370Df941591a8Fe1156F4Be0D3",
    //         ABI: "/abis/voting_escrow.json",
    //         // deployAt: 13468143
    //     },
    //     // mveFilDA: {
    //     //     address: "0xf6f9d296ff29d32bfA038Ec3f1cDCdC4cAA1E169",
    //     //     ABI: MirroredVotingEscrowABI
    //     // },
    //     rewardPolicyMaker: {
    //         address: "0x67fA32A35Bd12c9BBD1468dE318ee8a433E2064B",
    //         ABI: "/abis/rewardPolicyMaker.json"
    //     },
    //     gaugeController: {
    //         address: "0x28FAcBd979930665D0E3D5f39A7081CCC226d484",
    //         ABI: "/abis/gaugeController.json"
    //     },
    //     treasury: {
    //         address: "0x819575D30B5745d227B8a0f150e1EAF54B39a417"
    //     },
    //     gasLimit: {
    //         min: 21000,
    //         mid: 840000,
    //         max: 8000000
    //     },
    //     topics: {
    //         deposit: "0x4566dfc29f6f11d13a418c26a02bef7c28bae749d4de47e4e6a7cddea6730d59"
    //     },
    //     rewards: {
    //         symbol: "MJT",
    //         address: "0x208EecDBc49C137D0174B848DEf5F8cB74d6951E",
    //         decimals: 18
    //     },
    //     gauge: {
    //         ABI: "/abis/gauge.json",
    //         vault: {
    //             USDT: {
    //                 symbol: "USDT",
    //                 address: "0x92e03B067a04d140D10168D09225579D80Df58a7",
    //                 lpToken: {
    //                     address: "0xfC49791fF96187ad3260f47CeDdB9c440f25cB6E",
    //                     decimals: 18,
    //                     ABI: contractABI.qERC20,
    //                 },
    //                 underlying: {
    //                     address: "0x67f6a7BbE0da067A747C6b2bEdF8aBBF7D6f60dc",
    //                     decimals: 18,
    //                     ABI: contractABI.ERC20,
    //                 }
    //             },
    //             USDC: {
    //                 symbol: "USDC",
    //                 address: "0x1fB3D21BcaeD8c201D5f527f771eF30AFB6b1ca8",
    //                 lpToken: {
    //                     address: "0x3d41B01a94cd8538099005565b38de558CAE5EDA",
    //                     decimals: 18,
    //                     ABI: contractABI.qERC20,
    //                 },
    //                 underlying: {
    //                     address: "0xD6c7E27a598714c2226404Eb054e0c074C906Fc9",
    //                     decimals: 18,
    //                     ABI: contractABI.ERC20,
    //                 }
    //             }
    //         }
    //     }
    // }
}

export default {
    LanguageList,
    markets,
    priceOracle,
    comptroller,
    errorCodes,
    blockExplorers,
    chainIdMap,
    COMP,
    governorAlpha,
    compoundLens,
    maximillion,
    poolManager,
    interestRateModel,
    mdex,
    pools,
    noMintRewardPool,
    erc20,
    uniswapPair,
    voteProposal,
    mdexUrls,
    rpcUrls,
    apiUrls,
    WNATIVE,
    SwapRepayContract,
    swapRouter,
    LiquidateContract,
    FlashLoanContract,
    DepositRepayContract,
    DepositSwapContract,
    multiCall,
    walletConnect,
    thirdMarkets,
    FeeManagerContract,
    exchangeTitles,
    exchanges,
    leveragePools,
    useChainIdMap,
    excludeDepositSwap,
    leverageService,
    QsConfigABI,
    qsConfigAddress,
    approvalMsgToken,
    dQuick,
    lpSwapName,
    swapUrl,
    blockInterval,
    headerConfigData,
    liquidateMarkets,
    glideDecimals,
    QsPriceOracleForEla,
    QsPriceOracleForElaAddress,
    glideAddress,
    partners,
    community,
    navList,
    liquidateService,
    torchlight,
    nftPageStartDate,
    udAuthConfig,
    veToken
}

import React, { useState, useContext } from 'react'
import { Container, Button, Modal } from 'react-bootstrap'
import { WalletAddressContext, WalletTypeContext, NetworkTypeContext, Web3Context, LanguageContext } from '../../context'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import copy from 'copy-to-clipboard'
import Config from '../../utils/config'
import LinkIcon from '../../images/freshBank/link.svg'
import CopyIcon from '../../images/freshBank/copy.svg'
import SuccessIcon from '../../images/freshBank/success.svg'
import styles from './MyWalletModal.module.scss'



function MyWalletModal(props) {
    const { t, i18n } = useTranslation()
    const { connectedAddress } = useContext(WalletAddressContext)
    const { networkType } = useContext(NetworkTypeContext)
    const [copySuccess, setCopySuccess] = useState(false)

    const handleDisconnect = event => {
        return props.onDisconnect();
    };

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            className={classNames("customModal", styles.myWalletModal)}
            centered
            onHide={() => {
              setCopySuccess(false)
              props.onHide()
            }}
        >
            {
              copySuccess && (
                <div className={styles.copySuccess}>
                  <img src={SuccessIcon}/>
                  <span>{t('Header.MyWalletModal.CopySuccess')}</span>
                </div>
              )
            }
            <Modal.Header closeButton>
                <h2>{t('Header.MyWalletModal.MyWallet')}</h2>
            </Modal.Header>
            <Modal.Body>
                <div className="contentContainer">
                    <div className={classNames("formContainer", styles.formWrap)}>
                       <div className={styles.walletAddress}>
                         <div className={styles.title}>{t('Header.MyWalletModal.WalletAddress')}</div>
                         <div className={styles.content}>
                           <span>{connectedAddress}</span>
                           <img src={CopyIcon} onClick={() => {
                             copy(connectedAddress)
                             setCopySuccess(true)
                             setTimeout(() => {
                              setCopySuccess(false)
                             }, 3000)
                           }}/>
                         </div>
                       </div>
                       {
                         networkType && networkType!== "unsupported" && (
                          <>
                            <div className={styles.network}>
                              <div className={styles.title}>{t('Header.MyWalletModal.Network')}</div>
                              <div className={styles.content}>
                                <img src={Config.headerConfigData.network?.[networkType]?.img}/>
                                <span>{Config.headerConfigData.network?.[networkType]?.name}</span>
                              </div>
                            </div>
                            <a href={`${Config.blockExplorers?.[networkType]}address/${connectedAddress}`} className={styles.viewBlockChain} target="_blank" rel="noopener noreferrer">
                                <span>{t('Header.MyWalletModal.ViewInBlockchain')}</span>
                                <img src={LinkIcon}/>
                            </a>
                          </>
                         )
                       }

                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant='outline-red-lg'
                    className={styles.link}
                    onClick={handleDisconnect}>
                    {t("Header.DisconnectWallet")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default MyWalletModal
import React, { useContext, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { TwinLogo } from '../littleComponents'

export const ModalHeader = ({
  data, 
  title, 
  customLogo=''
}) => {
  const { t, i18n } = useTranslation()

  return (
    <>
      {
        data ? (
          <>
            { customLogo ? customLogo : 
                !data.isLPToken ? (
                  <img
                    className="logo"
                    height="24"
                    src={data?.logo}
                    alt={`${data?.name} Logo`}
                  />
                ) : (
                  <div className="logo">
                    <TwinLogo
                        mode={2}
                        logo1={data?.logo1}
                        logo2={data?.logo2} 
                    />
                  </div>
                )
            }
            <div className="assetName">{data?.name}</div>
          </>
        ) : title && (
          <div className="assetName">{title}</div>
        )
      }
    </>
  )
}
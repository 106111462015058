import React, { useState } from 'react'
import classNames from 'classnames'
import { Form, Button } from 'react-bootstrap'
import { TwinLogo } from '../../components/littleComponents'
import styles from "./ModalInput.module.scss";
import { ToggleAndSlider } from './ToggleAndSlider'

export const ModalInput = ({
  width,
  height,
  controlId,
  data,
  inputValue = '',
  showMax = false,
  maxText = "Max",
  showToggleButton = false,
  toggleButtonNum = [
    { key: "25%", value: 0.25 },
    { key: "50%", value: 0.5 },
    { key: "75%", value: 0.75 },
    { key: "100%", value: 1 }
  ],
  onChange = () => { },
  toggleButtonOnChange = () => { },
  maxOnClick = () => { },
  inputTips = '',
  showImg = false,
  disabled = false,
  fullWidth = false,
  textAlignLeft = false,
  toggleSlider = false
}) => {
  const charArr = ['e', '-', '+'];
  const [cleanUpToggles, setCleanUpToggles] = useState(false);

  const handleChange = event => {
    setCleanUpToggles(true);
    onChange(event);
  };

  const handleBlurInput = () => {
    setCleanUpToggles(false);
  };

  return (
    <Form.Group
      controlId={controlId}
      className={classNames(
        styles.inputContainer,
        showMax ? styles.inputContainerMax : null,
        fullWidth ? styles.fullWidth : null,
        textAlignLeft ? styles.textAlignLeft : null
      )}>
      <div className={classNames(styles.inputWrap, {
        "inputWrapShowImg": showImg,
        "inputWrapDisabled": disabled
      })}>
        {
          showImg && (
            !data?.isLPToken ?
              <img
                className="inputLogo"
                src={data?.logo}
              />
              :
              <div className="twoLogo">
                <TwinLogo
                  mode={2}
                  logo1={data?.logo1}
                  logo2={data?.logo2}
                />
              </div>
          )
        }
        <Form.Control
          className="txnValue"
          type="number"
          placeholder={"0.00 " + (data?.uiSymbol || data?.symbol)}
          autoComplete="off"
          value={inputValue}
          disabled={disabled}
          onChange={handleChange}
          onKeyPress={(e) => {
            if (charArr.includes(e.key.toLowerCase())) {
              e.preventDefault()
            }
          }}
          onBlur={handleBlurInput} />
        {showMax &&
          <Button
            className={styles.inputMax}
            onClick={maxOnClick}
          >
            {maxText}
          </Button>
        }
      </div>

      {showToggleButton && <ToggleAndSlider
        toggleButtonNum={toggleButtonNum}
        toggleSlider={toggleSlider}
        toggleButtonOnChange={toggleButtonOnChange}
        disabled={disabled}
        cleanUp={cleanUpToggles} />}

    </Form.Group>
  )


}
import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Container, Nav, Navbar, Button, Modal, DropdownButton, Dropdown } from 'react-bootstrap'
import Web3 from "web3";
import truncateMiddle from 'truncate-middle'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Carousel } from 'react-responsive-carousel';
import { WalletAddressContext, WalletTypeContext, NetworkTypeContext, Web3Context, LanguageContext, WalletModalContext } from '../../context'
import { noticeList } from '../../utils/notice';
import Config from '../../utils/config';

import CoreData from '../../methods/CoreData'
import { SwitchLang } from '../../components/littleComponents/switchLang'
import FreshBankText from '../../images/freshBank/logo_text.svg'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from './commonHeader.module.scss'
import config from '../../utils/config';
import ConnectWalletModal from '../modals/ConnectWalletModal'
import MyWalletModal from '../modals/MyWalletModal'

import WalletIcon from '../../images/freshBank/wallet.svg'
import WrongNetwork from '../../images/freshBank/wrong-network.svg'
import { RiBroadcastFill } from "@react-icons/all-files/ri/RiBroadcastFill"
import { globalUtils } from '../../utils/globalUtils';
import { isMobile } from 'react-device-detect'
import navbarToggle from '../../images/freshBank/navbarToggle.svg'
import { useWindowWidth } from '@react-hook/window-size'
import { createWalletConnectWeb3Provider, getActiveWalletType } from '../../methods/Wallet';

export const CommonHeader = props => {
  const { connectedAddress } = useContext(WalletAddressContext)
  const { walletType } = useContext(WalletTypeContext)
  const { networkType } = useContext(NetworkTypeContext)
  const { connectWalletModalShow, setConnectWalletModalShow } = useContext(WalletModalContext)
  const { web3, updateWeb3Env } = useContext(Web3Context);
  const [showWallet, setWalletShow] = useState(false);
  const [showNetwork, setNetworkShow] = useState(false);
  const [showMenuModal, setMenuModalShow] = useState(false);
  const { t, i18n } = useTranslation()
  const { pathname } = useLocation()
  const { language, setLanguage } = useContext(LanguageContext);

  const location = useLocation();
  const [isClicked, setIsClicked] = useState(false);
  const [isSwitchLangClicked, setIsSwitchLangClicked] = useState(false);

  const clientWidth = useWindowWidth()
  const mobileMode = isMobile || clientWidth < 992;
  const [displayTitle, setDisplayTitle] = useState("");

  globalUtils.getUDAuth().user().then(user => {
    if (user) {
      setDisplayTitle(user.sub)
    }
  }).catch(err => {
    console.warn(err);
  });


  // render UI
  const HeaderNav = () => {
    return Config.navList.map(i => {
      const validDate = !i.startDate || new Date(i.startDate).getTime() < new Date().getTime()
      return i.headerShow && validDate && (<NavLink
        key={i.name}
        exact
        activeClassName={styles.active}
        className={classNames(styles.menuItem, {
          [styles.menuItemHot]: i.hot
        })}
        to={{ pathname: i.sameDomain ? "/" + i18n.language + i.path : i.path }}
        target={i.isBlank ? '_blank' : '_self'}>
        <span className={styles.txt}>{t(i.txt)}</span>
      </NavLink>)
    });
  }

  const handleCloseWalletModal = event => {
    return setConnectWalletModalShow(false);
  };

  const handleDisconnect = async event => {
    // disconnect UnstoppableDomains
    try {
      await globalUtils.getUDAuth().logout();
    } catch (error) {
      console.warn(error);
    }

    window.localStorage.setItem(globalUtils.WALLET_CONNECTING, globalUtils.ConnectStatus.DISCONNECTED);
    handleCloseWalletModal();
    updateWeb3Env();
    // window.location.reload();
  };

  const changeLanguage = (e, code) => {
    if (window.location.pathname.indexOf("/" + language) === 0) {
      window.location.href = window.location.pathname.replace("/" + language, "/" + code);
    }
  }

  const showWalletLogo = () => {
    if (displayTitle != "") {
      return <img src={Config.headerConfigData?.wallet["unstoppabledomains"]?.img} width="20" />;
    } else if (walletType) {
      return <img src={Config.headerConfigData?.wallet[walletType]?.img} width="20" />;
    } else {
      return <></>;
    }
  };

  // 钱包
  const RenderWalletBtn = () => {
    return (
      <div className={styles.walletBtn}>
        {
          connectedAddress && connectedAddress.toLowerCase() !== '0x0000000000000000000000000000000000000000' && connectedAddress !== globalUtils.NA ? (
            <Button variant="outline-green-lg" className={styles.connectedAddress} onClick={() => setConnectWalletModalShow(true)} >
              {showWalletLogo()}

              <span>{displayTitle != "" ? displayTitle : truncateMiddle(connectedAddress, 4, 4, '...')}</span>
            </Button>
          ) : (
            <Button variant="outline-green-lg" className={styles.connectedAddress} onClick={() => setConnectWalletModalShow(true)} >
              <img src={WalletIcon} width={20} alt="" />
              <span>{t('Header.ConnectWallet')}</span>
            </Button>
          )
        }
      </div>
    )
  }

  const handleSwitchNetwork = async event => {
    const network = config.headerConfigData.network[event.currentTarget.id];
    if (network.chain.toLowerCase() === networkType.toLowerCase()) {
      // same chain
      return
    }

    const currentWallet = getActiveWalletType();
    const tempProvider = await globalUtils.getProvider(currentWallet);
    if (tempProvider && currentWallet !== "walletconnect") {
      try {
        await tempProvider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: Web3.utils.toHex(network.chainId) }],
        });

        // window.location.reload();
        setTimeout(() => {
          updateWeb3Env();
        }, 3000);
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await tempProvider.request({
              method: 'wallet_addEthereumChain',
              params: [{
                chainId: Web3.utils.toHex(network.chainId),
                rpcUrls: network.rpcUrls,
                chainName: network.chainName,
                blockExplorerUrls: network.blockExplorerUrls,
                nativeCurrency: network.nativeCurrency
              }],
            });
          } catch (addError) {
            console.error(addError)
          }
        } else {
          console.error(switchError)
        }
      }
    } else {
      // currentWallet==="walletconnect"

      let provider = web3?.currentProvider;
      if (!provider || !provider.connector) {
        provider = await createWalletConnectWeb3Provider();
      }

      const customRequest = {
        id: new Date().getTime(),
        jsonrpc: "2.0",
        method: "wallet_switchEthereumChain",
        params: [{ chainId: Web3.utils.toHex(network.chainId) }],
      };

      provider.connector.sendCustomRequest(customRequest).then(result => {
        window.location.reload();
      }).catch(error => {
        console.error(error);
      });
    }
  };

  // 切换网络
  const RenderSwitchNetwork = () => {

    const NetworkItem = () => {
      let result = []
      for (let [key, value] of Object.entries(config.headerConfigData.network)) {
        result.push((
          <Dropdown.Item
            key={key}
            id={key}
            href="#"
            onClick={handleSwitchNetwork}>
            <img src={value.img} />
            <span>{value.name}</span>
          </Dropdown.Item>
        ))
      }
      return result
    }

    let timer = null

    const dropdownButtonProps = {
      show: isClicked,
      variant: networkType && networkType === "unsupported" ? 'switchNetwork-wrong' : 'switchNetwork',
      size: "sm",
      id: "dropdown-switchNetwork",
      title: networkType && networkType === "unsupported" ? (
        <div className={classNames(styles.networkBtn, styles.wrongNetwork)}>
          <img src={WrongNetwork} />
          <span>{t('Header.WrongNetwork')}</span>
        </div>
      ) : (
        <div className={classNames(styles.networkBtn, styles.rightNetwork)}>
          <img src={config.headerConfigData.network[networkType]?.img} />
          <span>{config.headerConfigData.network[networkType]?.name || ''}</span>
        </div>
      )
    }

    if (mobileMode) {
      dropdownButtonProps.onToggle = () => setIsClicked(!isClicked)
    } else {
      dropdownButtonProps.onMouseEnter = () => {
        clearTimeout(timer)
        setIsClicked(true)

      }
      dropdownButtonProps.onMouseLeave = () => {
        clearTimeout(timer)
        timer = setTimeout(() => {
          setIsClicked(false)
        }, 500)
      }
      dropdownButtonProps.onSelect = () => {
        setIsClicked(false)
      }
    }

    // initial network env when web3 is injecting
    if (!networkType) return null


    return connectedAddress && connectedAddress.toLowerCase() !== '0x0000000000000000000000000000000000000000' && (
      <div className={classNames('tor-dropdown', styles.switchNetworkWrap)}>
        <DropdownButton
          {...dropdownButtonProps}
        >
          {NetworkItem()}
        </DropdownButton>
      </div>
    )
  }

  // 切换语言
  const RenderSwitchLang = () => {

    let timer = null

    const dropdownButtonProps = {
      show: isSwitchLangClicked,
      variant: 'switchNetwork',
      id: 'dropdown-switchLang',
      title: config.LanguageList.find(item => item.key === language)?.name
    }

    if (mobileMode) {
      dropdownButtonProps.onToggle = () => setIsSwitchLangClicked(!isSwitchLangClicked)
    } else {
      dropdownButtonProps.onMouseEnter = () => {
        clearTimeout(timer)
        setIsSwitchLangClicked(true)

      }
      dropdownButtonProps.onMouseLeave = () => {
        clearTimeout(timer)
        timer = setTimeout(() => {
          setIsSwitchLangClicked(false)
        }, 500)
      }
      dropdownButtonProps.onSelect = () => {
        setIsSwitchLangClicked(false)
      }
    }

    return (
      <div className={classNames('tor-dropdown', styles.switchLangWrap)}>
        <DropdownButton {...dropdownButtonProps}>
          {
            Config.LanguageList.map((i) => (
              <Dropdown.Item key={i.key} href="#" onClick={(e) => changeLanguage(e, i.key)}>{i.name}</Dropdown.Item>
            ))
          }
        </DropdownButton>
      </div>
    )
  }

  return (
    <div className={classNames(styles.headerWrap, {
      [styles.headerWrapNoBg]: pathname.endsWith('nft')
    })}>
      <Navbar className={styles.header} collapseOnSelect expand="lg">
        <Container className={styles.headerContainer}>

          <div className={styles.menuLogo}>
            <Navbar.Toggle aria-controls="responsive-navbar-nav">
              <img src={navbarToggle} width={15} />
            </Navbar.Toggle>
            <Navbar.Brand className={styles.logoWrap} href={"/" + language}>
              <img
                className={styles.logo}
                src={FreshBankText}
                width="140"
                height="33"
                alt="Torches Logo"
              />
            </Navbar.Brand>
          </div>
          {mobileMode && RenderWalletBtn()}



          <Navbar.Collapse id="responsive-navbar-nav" className={styles.navWrap}>
            <Nav className={styles.menuItemContainer}>
              {HeaderNav()}
            </Nav>
            <Nav className={styles.buttonsContainer}>
              {RenderSwitchNetwork()}
              {!mobileMode && RenderWalletBtn()}
              {RenderSwitchLang()}
            </Nav>
          </Navbar.Collapse>
        </Container>
        {
          connectedAddress && connectedAddress.toLowerCase() !== '0x0000000000000000000000000000000000000000' && connectedAddress !== globalUtils.NA ? (
            <MyWalletModal
              show={connectWalletModalShow}
              onHide={handleCloseWalletModal}
              onDisconnect={handleDisconnect} />
          ) : (
            <ConnectWalletModal
              show={connectWalletModalShow}
              onHide={handleCloseWalletModal}
              onDisconnect={handleDisconnect}
            />
          )
        }

      </Navbar>
      <div className={classNames(styles.tipsContainer)}>
        <Container>
          {
            networkType === "unsupported" &&
            <p className={classNames(styles.errorInfo, 'px-0')}>
              <RiBroadcastFill />
              <span>{t('Header.UnsupportNetwork')}</span>
            </p>
          }
        </Container>
      </div>
    </div>

  )
}


